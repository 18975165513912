var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(_vm._b({}, "div", attrs, false), on),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.renameDialog,
        callback: function ($$v) {
          _vm.renameDialog = $$v
        },
        expression: "renameDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Rename " + _vm._s(_vm.current.long_id))]),
          _c(
            "v-card-text",
            { staticClass: "pt-3" },
            [
              _c("v-text-field", {
                attrs: {
                  autofocus: "",
                  label: "New name for application",
                  disabled: _vm.loading,
                  outlined: "",
                },
                model: {
                  value: _vm.newName,
                  callback: function ($$v) {
                    _vm.newName = $$v
                  },
                  expression: "newName",
                },
              }),
              _c("v-textarea", {
                attrs: {
                  label: "Description",
                  disabled: _vm.loading,
                  outlined: "",
                },
                model: {
                  value: _vm.newDescription,
                  callback: function ($$v) {
                    _vm.newDescription = $$v
                  },
                  expression: "newDescription",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "secondary",
                    text: "",
                    disabled: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.renameDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "", loading: _vm.loading },
                  on: { click: _vm.updateAppName },
                },
                [_vm._v("Rename")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }